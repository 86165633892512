@font-face {
  font-family: "Proxima";
  src: local("Proxima"),
    url(/static/media/ProximaNova-Regular.410504d4.otf) format("opentype");
}
@font-face {
  font-family: "ProximaLight";
  src: local("ProximaLight"),
    url(/static/media/Proxima-Nova-Alt-Light.e2602b2f.otf)
      format("opentype");
}
@font-face {
  font-family: "Rbno3";
  src: local("Rbno3"),
    url(/static/media/RBNo3.1-Bold.773b719b.otf) format("opentype");
}
@font-face {
  font-family: "Rbno3Medium";
  src: local("Rbno3Medium"),
    url(/static/media/RBNo3.1-Medium.c9e982b2.otf) format("opentype");
}
body {
  margin: 0;
  padding: 0;
  /* using montserrat in place of proxima nova and roboto inplace of RBNo3.1 */
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Montserrat",
    "Proxima", "ProximaLight", "Rbno3", "Rbno3Medium" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  height: 100vh;
}
@media (max-width: 765px) {
  #root {
    min-height: 640px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navItems {
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 25vw;
  height: 30px;
  float: left;
  font-size: 30px;
}
.landingButton {
  border-radius: 33px;
  background-color: #66cc99;
  width: 120px;
  height: 36px;
  top: 10px;
  border: none;
}

/* text styles */
div.ant-typography {
  font-family: Proxima, sans-serif !important;
  color: #444142 !important;
}
h1.ant-typography {
  font-family: Rbno3, sans-serif !important;
}

h2.ant-typography {
  font-family: Rbno3Medium, sans-serif !important;
}
h3.ant-typography {
  font-family: Roboto, sans-serif !important;
}
span.ant-typography {
  font-family: Proxima, sans-serif !important;
}
/* override h4 styling */
h4.ant-typography {
  color: #7f8b95 !important;
}

/* home page styles */
.homeContainer {
  height: 90vh;
  padding: 10% 20%;
  text-align: left;
  text-align: initial;
}
.searchButton {
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border: none;
}
.searchBox {
  margin: 5% 0;
}
.resultContainer {
  display: flex;
  justify-content: space-around;
  padding: 2% 3%;
  background: #e5e5e5;
}
.adContainer {
  width: 20vw;
}
.jobContainer {
  width: 50vw;
  text-align: -webkit-auto;
}
.filterCard {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  margin-bottom: 12%;
  text-align: left;
  padding: 0 9%;
}
.descriptionBox {
  margin-right: 17%;
}
.adCard {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  margin-bottom: 12%;
  text-align: left;
}
.ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.jobCard {
  text-align: -webkit-auto;
}

.jobCardButtons {
  display: flex;
  justify-content: space-between;
}
.secondaryButton {
  border: none;
  color: #66cc99;
  font-weight: bold;
  padding: 0;
}
.modalButtons {
  display: flex;
  justify-content: space-between;
}
.cardFooter {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #93a4b1;
}

/* login styles */
.authContainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: scroll;
}
.logoContainer {
  padding: 2vh 0 0 0;
  margin: auto;
  width: 27vw;
  min-width: 300px;
  height: 10vh;
  display: flex;
  justify-content: flex-start;
}
.authCard {
  margin: 0 auto;
  min-width: 300px;
  width: 45vw;
  top: 5vh;
  background: #ffffff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  text-align: center;
}
.authForm {
  text-align: -webkit-auto;
}
.login-form-button {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 210px;
  /* height: 45px; */
}

.firebaseButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center !important;
  align-items: center;
  display: inline-flex !important;
  height: 40px !important;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  opacity: 0.8;
  border-color: unset;
}
.ant-menu-horizontal {
  line-height: 8vh !important;
}
.ant-menu-horizontal > .ant-menu-item-selected {
  color: #b0d157 !important;
  border-bottom: 2px solid #b0d157 !important;
}
.ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}
.ant-radio-button-wrapper {
  background: #ffffff !important;
  color: #444142 !important;
  border: 1px solid #b5b4b4;
  text-align: center;
  width: 100%;
  border-radius: 0 !important;
}
.ant-radio-button-wrapper-checked {
  background: #a537fd !important;
  color: #ffffff !important;
}
.ant-select-dropdown-menu-item:hover {
  background-color: #dda0dd !important;
}
.ant-dropdown-menu-item:hover {
  background-color: #dda0dd !important;
}

.ant-table-thead > tr > th {
  background: #b0d157 !important;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-modal-footer {
  text-align: center !important;
}
.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #ffffff;
}
.ql-toolbar {
  background: #ffffff !important;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}
@media (max-width: 765px) {
  h1.ant-typography {
    font-size: 30px !important;
  }
  h2.ant-typorgraphy {
    font-size: 27px !important;
  }
  h3.ant-typography {
    font-size: 25px !important;
  }
  .firebaseButton {
    width: 100%;
  }
}
@media (max-width: 1025px) {
  h2.ant-typography {
    font-size: 30px !important;
  }
}

