@font-face {
  font-family: "Proxima";
  src: local("Proxima"),
    url(./assets/Fonts/proxima_ssv/ProximaNova-Regular.otf) format("opentype");
}
@font-face {
  font-family: "ProximaLight";
  src: local("ProximaLight"),
    url(./assets/Fonts/proxima_ssv/Proxima-Nova-Alt-Light.otf)
      format("opentype");
}
@font-face {
  font-family: "Rbno3";
  src: local("Rbno3"),
    url(./assets/Fonts/RBNo3.1/RBNo3.1-Bold.otf) format("opentype");
}
@font-face {
  font-family: "Rbno3Medium";
  src: local("Rbno3Medium"),
    url(./assets/Fonts/RBNo3.1/RBNo3.1-Medium.otf) format("opentype");
}
body {
  margin: 0;
  padding: 0;
  /* using montserrat in place of proxima nova and roboto inplace of RBNo3.1 */
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Montserrat",
    "Proxima", "ProximaLight", "Rbno3", "Rbno3Medium" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  height: 100vh;
}
@media (max-width: 765px) {
  #root {
    min-height: 640px;
  }
}
